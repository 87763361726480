import { IsNotEmpty, IsString } from 'class-validator';

export interface EntityMeta
{
    additionalPermissions?: string[];
}

export class Entity
{
    @IsString()
    @IsNotEmpty()
    id!: string;

    meta?: EntityMeta & any;
}

export class AddEntityMemberDto
{
    @IsString()
    @IsNotEmpty()
    id!: string;
}

export class AddEntityMembersDto
{
    @IsString()
    @IsNotEmpty()
    property!: string;

    @IsString()
    @IsNotEmpty()
    regex!: string;
}