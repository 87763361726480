import { NgModule } from '@angular/core';
import { KeycloakAngularModule } from 'keycloak-angular';
import { UsersApiModule } from '../users';

@NgModule({
    imports: [
        UsersApiModule,
        KeycloakAngularModule
    ]
})
export class AuthModule {}