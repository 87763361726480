
export interface LatLngPoint
{
    lat: number;
    lng: number;
}
export interface Radius
{
    value: number;
    units?: 'M';
}

export interface Circle
{
    center: LatLngPoint;
    radius: Radius ;
}

export interface Polygon
{
    vertices: LatLngPoint[];
}

export interface Altitude
{
    value: number;
    reference?: 'W84';
    units?: 'M';
}
export interface Volume3D
{
    outline_circle?: Circle;
    outline_polygon?: Polygon;
    altitude_lower: Altitude;
    altitude_upper: Altitude;
}

export interface Time
{
    value: string;
    format?: 'RFC3339';
}

export interface Volume4D
{
    volume: Volume3D;
    time_start?: Time;
    time_end?: Time;
    id?:string;
}


export enum OperationMode
{
    vlos = 'vlos',
    bvlos = 'bvlos',
}

export enum OperationCategory
{
    open = 'open',
    specific = 'specific',
    certified = 'certified'
}

export enum UasClass
{
    C0 = 'C0',
    C1 = 'C1',
    C2 = 'C2',
    C3 = 'C3',
    C4 = 'C4',
    C5 = 'C5',
    C6 = 'C6',
    other = 'other'
}

export interface FlightAuthorisation
{
    uas_serial_number: string;
    operation_mode: OperationMode;
    operation_category: OperationCategory;
    uas_class: UasClass;
    uas_type_certificate?: string;
    identification_technologies: string[];
    connectivity_methods: string[];
    endurance_minutes: number;
    emergency_procedure_url: string;
    operator_id: string;
    uas_id: string;
}

export interface FlightPlan
{
    name?: string;
    volumes?: Volume4D[];
    off_nominal_volumes?:Volume4D[];
    priority: number;
    flight_authorisation?: FlightAuthorisation;
}

export enum Status
{
    Pending = 'pending',
    Accepted = 'accepted',
    Conflict = 'conflict',
    Error = 'error'
}
export interface OperationalIntentDetails extends FlightPlan {}
export interface OperationalIntent
{
    name?: string;
    uuid: string;
    details: OperationalIntentDetails;
    status: Status;
    user: string;
}


export const DEFAULT_FlightAuthorisation: FlightAuthorisation =
{
  uas_serial_number: '',//'SN-RANDOM',
  operation_mode: OperationMode.vlos,
  operation_category: OperationCategory.open,
  uas_class: UasClass.C0,
  uas_type_certificate: '',
  identification_technologies: [''],//['netrid', 'ads-b'],
  connectivity_methods: [''],//['cellular'],
  endurance_minutes: 20,
  emergency_procedure_url: 'https://url.com',//'https://utm_uss.com/emergency_procedure',
  operator_id: 'SUS5tub1rtahnayh-ldj',
  uas_id: ''//'HB-XXXX',
}

export const DEFAULT_FlightPlan: FlightPlan = {
    volumes: [],
    priority: 0,
    off_nominal_volumes: [],
    name: '',
    flight_authorisation: DEFAULT_FlightAuthorisation
};

export const DEFAULT_OperationalIntent: OperationalIntent = {
      uuid: '',
      user: '',
      status: Status.Pending,
      details: DEFAULT_FlightPlan
}