import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AddEntityMemberDto, AddEntityTypeDto, Entity } from 'involi-api-shared';
import { Observable } from 'rxjs';
import { ApiConfig, API_CONFIG } from '../api';
import { ApiClient } from '../core/api-client';
import { ApiStatusService } from '../core/api-status.service';

@Injectable({
    providedIn: 'root'
})
export class EntityApiService extends ApiClient
{
    private apiUrl: string;

    constructor(@Inject(API_CONFIG) config: ApiConfig,
                apiStatus: ApiStatusService,
                http: HttpClient)
    {
        super(apiStatus, http, 'Entity');
        this.apiUrl = `${config.involiApiUrl}/entity`;
    }

    createEntity(entityType: string, entity: Entity): Observable<void>
    {
        return this.post<void>(`${this.apiUrl}/${entityType}`, entity);
    }

    getEntities<T>(entityType: string): Observable<T[]>
    {
        return this.get<T[]>(`${this.apiUrl}/${entityType}`, []);
    }

    deleteEntity(entityType: string, entityId: string): Observable<void>
    {
        return this.delete<void>(`${this.apiUrl}/${entityType}/${entityId}`);
    }

    getEntityMembers<T>(entityType: string, entityId: string, memberEntityType: string): Observable<T[]>
    {
        return this.get<T[]>(`${this.apiUrl}/${entityType}/${entityId}/members/${memberEntityType}`);
    }

    addEntityMember(entityType: string, entityId: string, memberEntityType: string, memberEntityId: string): Observable<void>
    {
        const request = new AddEntityMemberDto();
        request.id = memberEntityId;
        return this.post<void>(`${this.apiUrl}/${entityType}/${entityId}/members/${memberEntityType}`, request);
    }

    removeEntityMember(entityType: string, entityId: string, memberEntityType: string, memberEntityId: string): Observable<void>
    {
        return this.delete<void>(`${this.apiUrl}/${entityType}/${entityId}/members/${memberEntityType}/${memberEntityId}`);
    }

    getEntityPointOfView<T>(entityType: string, entityId: string): Observable<T[]>
    {
        return this.get<T[]>(`${this.apiUrl}/${entityType}/${entityId}/pov`);
    }

    setEntityPointOfView(entityType: string, entityId: string, targetEntityType: string, targetEntityId: string): Observable<void>
    {
        return this.put<void>(`${this.apiUrl}/${entityType}/${entityId}/pov/${targetEntityType}/${targetEntityId}`, {});
    }

    removeEntityPointOfView(entityType: string, entityId: string): Observable<void>
    {
        return this.delete<void>(`${this.apiUrl}/${entityType}/${entityId}/pov`);
    }

    getEntityDefaultPointOfView<T>(entityType: string, entityId: string): Observable<T[]>
    {
        return this.get<T[]>(`${this.apiUrl}/${entityType}/${entityId}/default-pov`);
    }

    setEntityDefaultPointOfView(entityType: string, entityId: string, targetEntityType: string, targetEntityId: string): Observable<void>
    {
        return this.put<void>(`${this.apiUrl}/${entityType}/${entityId}/default-pov/${targetEntityType}/${targetEntityId}`, {});
    }

    removeEntityDefaultPointOfView(entityType: string, entityId: string): Observable<void>
    {
        return this.delete<void>(`${this.apiUrl}/${entityType}/${entityId}/default-pov`);
    }

    addEntityType(entityType: string, newEntityType: string): Observable<number>
    {
        const request: AddEntityTypeDto = { type: newEntityType };
        return this.post<number>(`${this.apiUrl}/${entityType}/add-entity-type`, request);
    }
}
