import { IsEmail, IsNotEmpty, IsString } from 'class-validator';

export class CreateUserDto
{
    @IsString()
    @IsNotEmpty()
    id!: string;

    @IsEmail()
    email!: string;
}