import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfig, API_CONFIG } from '../api';
import { TrackersApiModule } from './trackers-api.module';
import { AssignTrackerToGroupDto, NewTrackerDto, SetTrackerCustomNameDto, TrackerMetadata } from './trackers';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: TrackersApiModule
})
export class TrackersApiService
{
    constructor(@Inject(API_CONFIG) private apiConfig: ApiConfig, private http: HttpClient)
    {
        
    }

    getAllTrackers(): Observable<TrackerMetadata[]>
    {
        return this.getAllTrackersWithParameters(new HttpParams());
    }

    getMyTrackers(): Observable<TrackerMetadata[]>
    {
        return this.http.get<TrackerMetadata[]>(`${this.apiConfig.url}/my-trackers/`);
    }

    getAllGroupTrackers(groupUuid: string): Observable<TrackerMetadata[]>
    {
        const parameters = new HttpParams({ fromObject: { group: groupUuid }});
        return this.getAllTrackersWithParameters(parameters);
    }

    getAllUserTrackers(userUuid: string): Observable<TrackerMetadata[]>
    {
        const parameters = new HttpParams({ fromObject: { user: userUuid }});
        return this.getAllTrackersWithParameters(parameters);
    }

    private getAllTrackersWithParameters(parameters: HttpParams): Observable<TrackerMetadata[]>
    {
        return this.http.get<TrackerMetadata[]>(`${this.apiConfig.url}/trackers/`, { params: parameters });
    }

    getTrackerBySerialNumber(serial_number: string): Observable<TrackerMetadata>
    {
        return this.http.get<TrackerMetadata>(`${this.apiConfig.url}/trackers/${serial_number}/`);
    }

    createTracker(serialNumber: string): Observable<TrackerMetadata>
    {
        const newTrackerDto: NewTrackerDto = { serial_number: serialNumber };
        return this.http.post<TrackerMetadata>(`${this.apiConfig.url}/trackers/`, newTrackerDto);
    }

    updateTracker(uuid: string, tracker: TrackerMetadata): Observable<TrackerMetadata>
    {
        return this.http.patch<TrackerMetadata>(`${this.apiConfig.url}/trackers/${uuid}/`, tracker);
    }

    deleteTracker(uuid: string): Observable<any>
    {
        return this.http.delete(`${this.apiConfig.url}/trackers/${uuid}/`);
    }

    assignTrackerToGroup(trackerUuid: string, groupUuid: string)
    {
        const request: AssignTrackerToGroupDto = {
            group_id: groupUuid,
            tracker_id: trackerUuid
        };
        return this.http.post(`${this.apiConfig.url}/tracker-group-assign/`, request);
    }

    removeTrackerFromGroup(trackerUuid: string, groupUuid: string)
    {
        const request: AssignTrackerToGroupDto = {
            group_id: groupUuid,
            tracker_id: trackerUuid
        };
        return this.http.request('delete', `${this.apiConfig.url}/tracker-group-assign/`, { body: request });
    }

    setTrackerCustomName(trackerUuid: string, name: string)
    {
        const request: SetTrackerCustomNameDto = { name };
        return this.http.put(`${this.apiConfig.url}/tracker-name/${trackerUuid}/`, request);
    }

    getTrackerOwners(): Observable<string[]>
    {
        return this.http.get<string[]>(`${this.apiConfig.url}/tracker-owners/`).pipe(map((owners: string[]) => owners.filter((owner: string) => !!owner)));
    }
}
