import { Injectable, ErrorHandler } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class GlobalErrorHandler extends ErrorHandler
{
    handleError(error: any)
    {
        console.error('Error from global error handler', error);
    }
}