import { Component } from '@angular/core';
import { NestedTreeControl } from '@angular/cdk/tree';

export interface SideMenuNode
{
    title: string;
    url: string;
    icon?: string;
    children?: SideMenuNode[];
}

@Component({
    selector: 'side-nav',
    templateUrl: 'side-nav.component.html',
    styleUrls: ['side-nav.component.scss']
})
export class SideNavComponent
{
    treeControl = new NestedTreeControl<SideMenuNode>(node => node.children);

    readonly menuData: SideMenuNode[] = [
        { title: 'System', url: '/system', children: [
            { title: 'Overview (soon)', url: '/system/overview' },
            { title: 'Configuration', url: '/system/configuration' }
        ]},
        { title: 'Users', url: '/users', children: [
            { title: 'Overview', url: '/users/overview' },
            { title: 'Groups', url: '/users/groups' },
            { title: 'Tokens', url: '/users/tokens' }
        ]},
        { title: 'Trackers', url: '/trackers', children: [
            { title: 'Overview', url: '/trackers/overview' }
        ]},
        { title: 'Features', url: '/features', children: [
            { title: 'Overview', url: '/features/overview' },
            { title: 'Streams', url: '/features/streams' }
        ]},
        { title: 'Data', url: '/data', children: [
            { title: 'Streams', url: '/data/streams' }
        ]},
        { title: 'Zones', url: '/zones', children: [
            { title: 'Overview', url: '/zones/overview' }
        ]},
        { title: 'MCTs', url: '/mct', children: [
            { title: 'Overview', url: '/mct/overview' }
        ]},
        { title: 'RemoteID', url: '/remote-id', children : [
            { title: 'Overview', url: '/remote-id/overview' }
        ]},
        { title: 'Entity', url: '/entity', children : [
            { title: 'Overview', url: '/entity/overview' },
            { title: 'Receiver', url: '/entity/receiver' },
            { title: 'Permission', url: '/entity/permission' },
            { title: 'User-Organization', url: '/entity/user-organization' },
            { title: 'Client', url: '/entity/client' }
        ]},
        { title: 'API', url: '/api', children : [
            { title: 'Overview', url: '/api/overview' },
            { title: 'Migration', url: '/api/migration' }
        ]},
        { title: 'Tools', url: '/tools', children : [
            { title: 'Websocket', url: '/tools/websocket' },
            { title: 'RabbitMQ Consumer', url: '/tools/rabbitmq-consumer' },
            { title: 'Involi API Client', url: '/tools/involi-api-client' }
        ]}
    ];

    hasChild = (_: number, node: SideMenuNode) => !!node.children && node.children.length > 0;
}