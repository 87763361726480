import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { BaseConfiguration } from './configuration';
import { ConfigurationModule } from './configuration.module';
import { firstValueFrom } from 'rxjs';

@Injectable({
    providedIn: ConfigurationModule
})
export class ConfigurationService
{
    readonly CURRENT_REALM_KEY = 'involi-api-current-realm';
    private http: HttpClient;
    configuration?: BaseConfiguration;

    constructor(httpBackend: HttpBackend)
    {
        this.http = new HttpClient(httpBackend);
    }

    async load(): Promise<BaseConfiguration>
    {
        this.configuration = await firstValueFrom(this.http.get<BaseConfiguration>('assets/configuration.json'));
        if(!this.configuration.involiAuthConfig.currentRealm)
            this.configuration.involiAuthConfig.currentRealm = localStorage.getItem(this.CURRENT_REALM_KEY) || 'involi';
        return this.configuration;
    }

    setAuthRealm(realm: string)
    {
        localStorage.setItem(this.CURRENT_REALM_KEY, realm);
    }
}