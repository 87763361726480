import { IsEnum } from 'class-validator';

export enum OperationalStatus
{
    NotApplicable = 'NotApplicable',
    Test = 'Test',
    Production = 'Production'
}

export interface ReceiverMetadata
{
    operational_status: OperationalStatus | null;
}

export class SetOperationalStatusDto
{
    @IsEnum(OperationalStatus)
    operational_status!: OperationalStatus;
}