import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Record } from 'involi-api-shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiConfig, API_CONFIG } from '../api';
import { Stream } from '../streams';
import { TrafficDto, TrafficFromLayersDto, GeographicalRestriction, TrafficFromStreamsDto } from './traffic';
import { TrafficApiModule } from './traffic-api.module';

@Injectable({
    providedIn: TrafficApiModule
})
export class TrafficApiService
{
    constructor(@Inject(API_CONFIG) private apiConfig: ApiConfig, private http: HttpClient)
    {

    }

    getTraffic(sources: string[]): Observable<Record[]>
    {
        const request: TrafficDto = {
            sources
        };
        return this.http.post<Record[]>(`${this.apiConfig.trafficUrl}/traffic/`, request);
    }

    getAdminTraffic(sources: string[]): Observable<Record[]>
    {
        const request: TrafficDto = {
            sources,
            source_params: { tracker: {} }
        };
        return this.http.post<Record[]>(`${this.apiConfig.trafficUrl}/traffic/`, request);
    }

    getGroupedTraffic(sources: string[]): Observable<Record[]>
    {
        const request: TrafficDto = {
            display_groups: sources.map((source: string) => ({ id: source, sources: [source] }))
        };
        return this.http.post<Record[]>(`${this.apiConfig.trafficUrl}/traffic/`, request);
    }

    getTrafficFromLayers(layers: string[], restriction?: GeographicalRestriction): Observable<Record[]>
    {
        const request: TrafficFromLayersDto = { layers };
        if(restriction?.areas)
            request.areas = restriction.areas;
        if(restriction?.boxes)
            request.boxes = restriction.boxes;
        return this.http.post<Record[]>(`${this.apiConfig.trafficUrl}/traffic-layers/`, request);
    }

    getTrafficFromStreams(streams: string[], restriction?: GeographicalRestriction): Observable<Record[]>
    {
        const request: TrafficFromStreamsDto = {
            layers: [{ streams }],
            boxes: restriction?.boxes
        };
        return this.http.post<Record[]>(`${this.apiConfig.trafficUrl}/traffic-streams/`, request);
    }

    getStreamList(): Observable<Stream[]>
    {
        return this.http.get<string[]>(`${this.apiConfig.trafficUrl}/live-streams/`).pipe(
            map((streams: string[]) => streams.map((stream: string): Stream => ({ name: stream })))
        );
    }
}
