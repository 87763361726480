export enum AlarmStatus
{
    alert = 'Alert',
    warning = 'Warning',
    clear = 'Clear',
    rien = 'Rien',
    noData = 'No Data'
}

export interface TrafficSourceParamDto
{
    tracker: {
        owner?: string;
        user?: string;
        group?: string;
        serial_number?: string;
    };
}

export interface TrafficConditionDto
{
    lt?: number;
    lte?: number;
    eq?: number|string;
    gte?: number;
    gt?: number;
}

export interface TrafficDisplayGroupDto
{
    id: string;
    sources?: string[];
    stream_sources?: string[];
    field_conditions?: TrafficConditionDto[];
}

export interface TrafficDto
{
    sources?: string[];
    source_params?: TrafficSourceParamDto;
    display_groups?: TrafficDisplayGroupDto[];
}


export interface Vertex
{
    latitude: number;
    longitude: number;
}

export interface TrafficArea
{
    vertices: Vertex[];
    winding?: string;
}

export interface TrafficBox
{
    bottom_left: Vertex;
    top_right: Vertex;
}

export interface TrafficFromLayersDto
{
    layers: string[];
    areas?: TrafficArea[];
    boxes?: TrafficBox[];
}

export interface TrafficFromStreamsDto
{
    layers: {
        streams: string[]
    }[];
    boxes?: TrafficBox[];
}

export interface GeographicalRestriction
{
    areas?: TrafficArea[];
    boxes?: TrafficBox[];
}