import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'realm-selection',
    templateUrl: 'realm-selection.component.html',
    styleUrls: ['realm-selection.component.scss']
})
export class RealmSelectionComponent
{
    realms: string[] = ['involi', 'swisscom', 'drone-spotter'];
    selectedRealm: string;

    constructor(private dialogRef: MatDialogRef<string>)
    {

    }

    selectRealm()
    {
        this.dialogRef.close(this.selectedRealm);
    }
}