import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfig, API_CONFIG } from '../api';
import { Feature, DefaultFeatureDto, GroupFeatureDto, WriteFeatureDto } from './feature';
import { FeaturesApiModule } from './features-api.module';
import { Platform } from './platform';

@Injectable({
    providedIn: FeaturesApiModule
})
export class FeaturesApiService
{
    constructor(@Inject(API_CONFIG) private apiConfig: ApiConfig,
                private http: HttpClient)
    {

    }

    getAllFeatures(): Observable<Feature[]>
    {
        return this.http.get<Feature[]>(`${this.apiConfig.url}/features/`);
    }

    getFeature(key: string): Observable<Feature>
    {
        return this.http.get<Feature>(`${this.apiConfig.url}/features/${key}`);
    }

    createFeature(key: string): Observable<void>
    {
        const feature: WriteFeatureDto = {
            key,
            requires_help: false
        };
        return this.http.post<void>(`${this.apiConfig.url}/features/`, feature);
    }

    updateFeature(key: string, feature: WriteFeatureDto): Observable<WriteFeatureDto>
    {
        return this.http.patch<WriteFeatureDto>(`${this.apiConfig.url}/features/${key}/`, feature);
    }

    deleteFeature(key: string): Observable<void>
    {
        return this.http.delete<void>(`${this.apiConfig.url}/features/${key}/`);
    }

    getCurrentUserFeatureHelp(): Observable<string[]>
    {
        return this.http.get<string[]>(`${this.apiConfig.url}/features-help/`);
    }

    acknowledgeFeature(key: string): Observable<void>
    {
        return this.http.put<void>(`${this.apiConfig.url}/features-ack/${key}/`, null);
    }

    getAllPlatforms(): Observable<Platform[]>
    {
        return this.http.get<Platform[]>(`${this.apiConfig.url}/platforms/`);
    }

    createPlatform(platform: Platform): Observable<Platform>
    {
        return this.http.post<Platform>(`${this.apiConfig.url}/platforms/`, platform);
    }
    
    deletePlatform(name: string): Observable<void>
    {
        return this.http.delete<void>(`${this.apiConfig.url}/platforms/${name}/`);
    }

    getDefaultFeatures(platform: string): Observable<Feature[]>
    {
        let params = new HttpParams().append('platform', platform);
        return this.http.get<Feature[]>(`${this.apiConfig.url}/features/`, { params });
    }

    addDefaultFeatures(platform: string, feature: string): Observable<void>
    {
        const defaultFeature: DefaultFeatureDto = {
            platform,
            feature
        };
        return this.http.post<void>(`${this.apiConfig.url}/default-features/`, defaultFeature);
    }

    removeDefaultFeature(platform: string, feature: string): Observable<void>
    {
        const defaultFeature: DefaultFeatureDto = {
            platform,
            feature
        };
        return this.http.request<void>('delete', `${this.apiConfig.url}/default-features/`, { body: defaultFeature });
    }

    getGroupFeatures(user_group: string): Observable<Feature[]>
    {
        let params = new HttpParams().append('user_group', user_group);
        return this.http.get<Feature[]>(`${this.apiConfig.url}/features/`, { params });
    }

    addGroupFeature(group_id: string, feature: string): Observable<void>
    {
        const groupFeature: GroupFeatureDto = {
            group_id,
            feature
        };
        return this.http.post<void>(`${this.apiConfig.url}/feature-group-assign/`, groupFeature);
    }

    removeGroupFeature(group_id: string, feature: string): Observable<void>
    {
        const groupFeature: GroupFeatureDto = {
            group_id,
            feature
        };
        return this.http.request<void>('delete', `${this.apiConfig.url}/feature-group-assign/`, { body: groupFeature });
    }
}