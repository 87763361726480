import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Record } from 'involi-api-shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiConfig, API_CONFIG } from '../api';
import { CreateZoneDto, Zone, ZoneResponseItemDto } from './zone';
import { ZonesApiModule } from './zones-api.module';

@Injectable({
    providedIn: ZonesApiModule
})
export class ZonesApiService
{
    constructor(@Inject(API_CONFIG) private apiConfig: ApiConfig,
                private http: HttpClient)
    {

    }

    getAllZones(): Observable<Record[]>
    {
        return this.http.get<Record[]>(`${this.apiConfig.zoneApiUrl}/zones/`);
    }

    getZone(uuid: string): Observable<Record>
    {
        return this.http.get<Record>(`${this.apiConfig.zoneApiUrl}/zones/${uuid}/`);
    }

    createZone(label: string, type: string): Observable<Zone>
    {
        const request: CreateZoneDto = { label, type };
        return this.http.post<ZoneResponseItemDto>(`${this.apiConfig.zoneApiUrl}/zones/`, request).pipe(
            map((r) => r.zone)
        );
    }

    updateZone(zone: Zone): Observable<Zone>
    {
        return this.http.patch<ZoneResponseItemDto>(`${this.apiConfig.zoneApiUrl}/zones/${zone.id}/`, {...zone, id: undefined}).pipe(
            map((r) => r.zone)
        );
    }

    deleteZone(uuid: string): Observable<any>
    {
        return this.http.delete(`${this.apiConfig.zoneApiUrl}/zones/${uuid}/`);
    }
}