import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { CdkTreeModule } from '@angular/cdk/tree';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthModule, ConfigurationModule, UsersApiModule } from 'involi-api-client';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { AdminPlatformComponent } from './admin-platform.component';
import { SideNavComponent } from './side-nav/side-nav.component';
import { UserHeaderComponent } from './user-header/user-header.component';
import { RealmSelectionComponent } from './realm-selection/realm-selection.component';

@NgModule({
    declarations: [
        AdminPlatformComponent,
        SideNavComponent,
        UserHeaderComponent,
        RealmSelectionComponent
    ],
    imports: [
        BrowserModule,
        RouterModule,
        HttpClientModule,
        BrowserAnimationsModule,
        MatToolbarModule,
        MatButtonModule,
        MatTooltipModule,
        MatIconModule,
        MatMenuModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        CdkTreeModule,
        MatButtonModule,
        AuthModule,
        ConfigurationModule,
        UsersApiModule
    ]
})
export class AdminPlatformModule {}
