import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { ApiConfig, API_CONFIG } from '../api';
import { Event, EventsStats } from './event';
import { Session, SessionsStats } from './session';

@Injectable()
export class SessionsService
{
    private readonly SESSION_ID_KEY = 'involi-session-id';
    private readonly SESSION_LAST_ACTIVITY_KEY = 'involi-session-last-activity';
    private readonly SESSION_TIMEOUT_MINUTES = 15;

    private sessionId!: string;
    private lastActivity?: number;

    constructor(@Inject(API_CONFIG) private apiConfig: ApiConfig,
                private http: HttpClient)
    {
        const sessionId: string | null = sessionStorage.getItem(this.SESSION_ID_KEY);
        let lastActivityStr: string | null = sessionStorage.getItem(this.SESSION_LAST_ACTIVITY_KEY);
        if(lastActivityStr)
            this.lastActivity = parseInt(lastActivityStr);

        if(!sessionId)
            this.generateSessionId();
        else
            this.sessionId = sessionId;
        this.activity();
    }

    getSessionId(): string
    {
        return this.sessionId;
    }

    activity()
    {
        const now = Math.floor(Date.now()/60000);
        if(!this.lastActivity || now - this.lastActivity > this.SESSION_TIMEOUT_MINUTES)
            this.generateSessionId();
        if(now != this.lastActivity)
        {
            this.lastActivity = now;
            sessionStorage.setItem(this.SESSION_LAST_ACTIVITY_KEY, `${this.lastActivity}`);
        }
    }

    private generateSessionId()
    {
        this.sessionId = uuid();
        sessionStorage.setItem(this.SESSION_ID_KEY, this.sessionId);
    }

    getSessions(userUuid: string): Observable<Session[]>
    {
        const params: HttpParams = new HttpParams({ fromObject: {
            user: userUuid
        }});
        return this.http.get<Session[]>(`${this.apiConfig.url}/sessions/`, { params });
    }

    getSessionsStats(): Observable<SessionsStats>
    {
        return this.http.get<SessionsStats>(`${this.apiConfig.url}/sessions-stats/`);
    }

    ping(): Observable<void>
    {
        return this.http.post<void>(`${this.apiConfig.url}/ping/`, {});
    }

    logEvent(action: string, state: boolean): Observable<Event>
    {
        const event: Event = { action, state };
        return this.http.post<Event>(`${this.apiConfig.url}/event/`, event);
    }

    eventsStats(action: string): Observable<EventsStats>
    {
        const params: HttpParams = new HttpParams({ fromObject: { action }});
        return this.http.get<EventsStats>(`${this.apiConfig.url}/events-stats/`, { params });
    }
}