import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfig, API_CONFIG } from '../api';
import { FlightPlan, OperationalIntent } from './flight-plan';
import { FlightPlanApiModule as FlightPlanApiModule } from './flight-plan-api.module';

@Injectable({
    providedIn: FlightPlanApiModule
})
export class FlightPlanApiService
{

    constructor(@Inject(API_CONFIG) private apiConfig: ApiConfig,
                private http: HttpClient)
    {
    }

    createFlightPlan(plan: FlightPlan): Observable<OperationalIntent>
    {
        const request: FlightPlan = plan;
        return this.http.post<OperationalIntent>(`${this.apiConfig.flightAuthorizationApiUrl}/operation_request/`, request);
    }

    getAllFlightPlans(): Observable<OperationalIntent[]>
    {
        return this.http.get<OperationalIntent[]>(`${this.apiConfig.flightAuthorizationApiUrl}/operational_intents/`);
    }

    deleteFlightPlan(uuid: string): Observable<any>
    {
        return this.http.delete(`${this.apiConfig.flightAuthorizationApiUrl}/operational_intents/${uuid}/`);
    }
}
