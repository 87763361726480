import { Entity } from '../entity/entity.js';
import { IsOptional, IsString } from 'class-validator';
import { makePrimaryId } from '../entity/utils.js';

export enum ReceiverUpdateStatus
{
    InProgress = 'in-progress',
    Aborting = 'aborting',
    Aborted = 'aborted',
    Success = 'success',
    Failed = 'failed'
}

export class ReceiverEntity extends Entity
{
    model?: string;
    firmware?: string;
    managementId?: string;
    updateStatus?: ReceiverUpdateStatus;
    deploymentId?: string;

    @IsOptional()
    @IsString()
    serialNumber?: string;

    @IsOptional()
    @IsString()
    stream?: string;

    constructor(baseEntity: Partial<ReceiverEntity>)
    {
        super();
        this.serialNumber = baseEntity.serialNumber;
        this.stream = baseEntity.stream;
        if(baseEntity.serialNumber && baseEntity.stream)
            this.id = makePrimaryId(baseEntity.stream, baseEntity.serialNumber);
    }
}