import { InvoliError, InvoliErrorCode } from 'involi-api-shared';
import { HttpClient, HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Observable, catchError, of, tap, switchMap, finalize } from 'rxjs';
import { ApiStatusService } from './api-status.service';
import { v4 as makeUuid } from 'uuid';

export class ApiClient
{
    private isServiceAvailable: boolean = true;
    private lastError?: InvoliError;
    private onGoingRequests: string[] = [];

    constructor(protected apiStatus: ApiStatusService,
                protected http: HttpClient,
                protected apiName: string)
    {
        this.apiStatus.registerApi(apiName);
    }

    protected get<T>(url: string, defaultValue?: T): Observable<T>
    {
        return this.handleError(this.http.get<T>(url), defaultValue);
    }

    protected post<T>(url: string, body: any | null, defaultValue?: T): Observable<T>
    {
        return this.handleError(this.http.post<T>(url, body), defaultValue);
    }

    protected put<T>(url: string, body: any, defaultValue?: T): Observable<T>
    {
        return this.handleError(this.http.put<T>(url, body), defaultValue);
    }

    protected delete<T>(url: string, defaultValue?: T): Observable<T>
    {
        return this.handleError(this.http.delete<T>(url), defaultValue);
    }

    private handleError<T>(request: Observable<T>, defaultValue?: T): Observable<T>
    {
        const uuid: string = makeUuid();
        return of(null).pipe(
            tap(() => this.onRequestStart(uuid)),
            switchMap(() => request),
            tap(() => {
                if(!this.isServiceAvailable)
                    this.setAvailability(true);
            }),
            catchError((error: HttpErrorResponse) => {
                this.setAvailability(false);
                if(error.status == HttpStatusCode.ServiceUnavailable
                    || error.status == HttpStatusCode.Forbidden
                    || error.status == HttpStatusCode.NotFound
                    || error.status == HttpStatusCode.Conflict
                    || error.status == HttpStatusCode.UnprocessableEntity)
                {
                    this.lastError = new InvoliError();
                    Object.assign(this.lastError, error.error);
                }
                else
                {
                    this.lastError = new InvoliError(InvoliErrorCode.ServiceUnreachable);
                }
                if(defaultValue)
                    return of(defaultValue);
                throw this.lastError;
            }),
            finalize(() => this.onRequestEnd(uuid))
        );
    }

    isAvailable(): boolean
    {
        return this.isServiceAvailable;
    }

    private setAvailability(available: boolean)
    {
        this.isServiceAvailable = available;
        if(this.isServiceAvailable)
            this.lastError = undefined;
        this.apiStatus.setApiStatus(this.apiName, this.isServiceAvailable);
    }

    private onRequestStart(requestUuid: string)
    {
        this.onGoingRequests.push(requestUuid);
        this.apiStatus.setActiveApiRequests(this.apiName, this.onGoingRequests.length);
    }

    private onRequestEnd(requestUuid: string)
    {
        const requestIndex = this.onGoingRequests.indexOf(requestUuid);
        if(requestIndex >= 0)
            this.onGoingRequests.splice(requestIndex, 1);
        this.apiStatus.setActiveApiRequests(this.apiName, this.onGoingRequests.length);
    }

    getLastError(): InvoliError | undefined
    {
        return this.lastError;
    }
}