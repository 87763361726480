import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { SessionsInterceptor } from './sessions.interceptor';
import { SessionsService } from './sessions.service';

@NgModule({
    providers: [
        SessionsService,
        { provide: HTTP_INTERCEPTORS, useClass: SessionsInterceptor, multi: true }
    ]
})
export class SessionsModule {}