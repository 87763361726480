import { InjectionToken } from '@angular/core';

export interface ApiConfig
{
    url: string;
    trafficUrl: string;
    receiverApiUrl: string;
    alarmsApiUrl: string;
    zoneApiUrl: string;
    flightAuthorizationApiUrl : string;
    entityApiUrl?: string;
    involiApiUrl?: string;
}

export const API_CONFIG = new InjectionToken<ApiConfig>('api_config');
