export enum InvoliErrorCode
{
    Ok,
    DataNotAvailable,
    InternalServerError,
    ServiceUnreachable,
    Forbidden,
    DataNotFound,
    InvalidParameter,
    InvalidOperation,
    Conflict,
    Unknown,
    KeycloakMissingCredentials,
    KeycloakGettingServiceAccountFailed
}

export class InvoliError extends Error
{
    constructor(public code: InvoliErrorCode = InvoliErrorCode.Unknown, public additionalMessage?: string)
    {
        super();
        this.message = errorCodeMessage(code, additionalMessage);
    }
}

export function involiReject(code: InvoliErrorCode, additionalMessage?: string)
{
    return Promise.reject(new InvoliError(code, additionalMessage));
}

export function errorCodeMessage(code: InvoliErrorCode, additionalMessage?: string): string
{
    let message: string;
    switch(code)
    {
    case InvoliErrorCode.Ok: message = 'No error'; break;
    case InvoliErrorCode.DataNotAvailable: message = 'Data currently not available'; break;
    case InvoliErrorCode.InternalServerError: message = 'Internal server error'; break;
    case InvoliErrorCode.ServiceUnreachable: message = 'Service unreachable'; break;
    case InvoliErrorCode.Forbidden: message = 'Forbidden'; break;
    case InvoliErrorCode.DataNotFound: message = 'Data not found'; break;
    case InvoliErrorCode.InvalidParameter: message = 'Invalid parameter'; break;
    case InvoliErrorCode.InvalidOperation: message = 'Invalid operation'; break;
    case InvoliErrorCode.Conflict: message = 'Conflict'; break;
    case InvoliErrorCode.Unknown: message = 'Unknown error'; break;
    case InvoliErrorCode.KeycloakMissingCredentials: message = 'Missing client credentials'; break;
    case InvoliErrorCode.KeycloakGettingServiceAccountFailed: message = 'Getting client\'s service account failed'; break;
    default: message = `Unknown error, code ${code}`; break;
    }
    if(!additionalMessage) return message;
    return `${message}: ${additionalMessage}`;
}