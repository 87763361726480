import { Component } from '@angular/core';
import { ApiStatusService } from 'involi-api-client';
import { Observable, tap, delay } from 'rxjs';

@Component({
    selector: 'admin-platform',
    templateUrl: 'admin-platform.component.html',
    styleUrls: ['admin-platform.component.scss']
})
export class AdminPlatformComponent
{
    apiStatus$: Observable<boolean>;
    activeRequests$: Observable<number>;
    koApis: string[];

    constructor(apiStatusService: ApiStatusService)
    {
        this.apiStatus$ = apiStatusService.globalStatus$.pipe(
            tap((status: boolean) => {
                if(!status)
                    this.koApis = apiStatusService.getKoApis();
            })
        );
        this.activeRequests$ = apiStatusService.activeRequests$.pipe(delay(0));
    }
}