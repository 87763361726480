import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfig, API_CONFIG } from '../api';
import { SetTrackerEntityCustomNameDto } from './tracker';
import { TrackerParametersDto, TrackerEntity, SetTrackerParameterDto } from 'involi-api-shared'
import { ApiClient } from '../core/api-client';
import { ApiStatusService } from '../core';

@Injectable({
    providedIn: 'root'
})
export class TrackerApiService extends ApiClient
{
    private apiUrl: string;

    constructor(@Inject(API_CONFIG) config: ApiConfig,
                http: HttpClient,
                apiStatus: ApiStatusService)
    {
        super(apiStatus, http, 'Tracker');
        this.apiUrl = `${config.involiApiUrl}/tracker`;
    }

    getAllTrackers(): Observable<TrackerEntity[]>
    {
        return this.http.get<TrackerEntity[]>(`${this.apiUrl}/all/entity`);
    }

    getMyTrackers(): Observable<TrackerEntity[]>
    {
        return this.http.get<TrackerEntity[]>(`${this.apiUrl}/entity`);
    }

    getAllUserTrackers(userUuid: string): Observable<TrackerEntity[]>
    {
        return this.http.get<TrackerEntity[]>(`${this.apiUrl}/user/${userUuid}/entity`);
    }

    setTrackerCustomName(trackerId: string, name: string): Observable<void>
    {
        const request: SetTrackerEntityCustomNameDto = { custom_name: name };
        return this.http.post<void>(`${this.apiUrl}/${trackerId}/custom-name`, request);
    }

    getTrackerParameters(trackerId: string): Observable<TrackerParametersDto>
    {
        return this.http.get<TrackerParametersDto>(`${this.apiUrl}/${trackerId}/parameters`);
    }

    updateTrackerParameter(trackerId: string, setting: SetTrackerParameterDto): Observable<void>
    {
        return this.http.post<void>(`${this.apiUrl}/${trackerId}/parameter`, setting);
    }
}
