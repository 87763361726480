import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RabbitMqPeekMessageDto } from 'involi-api-shared';
import { ApiConfig, API_CONFIG } from '../api';
import { Observable, timeout } from 'rxjs';
import { ApiClient } from '../core/api-client';
import { ApiStatusService } from '../core';

@Injectable({
    providedIn: 'root'
})
export class RabbitMqApiService extends ApiClient
{
    private apiUrl: string;

    constructor(@Inject(API_CONFIG) config: ApiConfig,
                http: HttpClient,
                apiStatus: ApiStatusService)
    {
        super(apiStatus, http, 'RabbitMQ');
        this.apiUrl = `${config.involiApiUrl}/rabbitmq`;
    }

    peekMessage(exchange: string, headers: { [key: string]: string }): Observable<string>
    {
        const request: RabbitMqPeekMessageDto = { exchange, headers };
        return this.post<string>(`${this.apiUrl}/peek`, request);
    }
}