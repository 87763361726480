import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfig, API_CONFIG } from '../api';
import { DronesApiModule } from './drones-api.module';
import { Drone, PatchDrone } from './drones';

@Injectable({
    providedIn: DronesApiModule
})
export class DronesApiService
{
    constructor(@Inject(API_CONFIG) private apiConfig: ApiConfig, private http: HttpClient)
    {

    }

    getAllDrones(): Observable<Drone[]>
    {
        return this.http.get<Drone[]>(`${this.apiConfig.url}/drones/`);
    }

    createDrone(drone: Drone): Observable<Drone>
    {
        return this.http.post<Drone>(`${this.apiConfig.url}/drones/`, drone);
    }

    updateDrone(uuid: string, drone: PatchDrone): Observable<Drone>
    {
        return this.http.patch<Drone>(`${this.apiConfig.url}/drones/${uuid}/`, drone);
    }

    deleteDrone(uuid: string): Observable<any>
    {
        return this.http.delete(`${this.apiConfig.url}/drones/${uuid}/`);
    }
}
