import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { LogLevel, LogLevelDto } from 'involi-api-shared';
import { map, Observable } from 'rxjs';
import { ApiConfig, API_CONFIG } from '../api';
import { ApiClient } from '../core/api-client';
import { ApiStatusService } from '../core/api-status.service';

@Injectable({
    providedIn: 'root'
})
export class AdminApiService extends ApiClient
{
    private apiUrl: string;

    constructor(@Inject(API_CONFIG) config: ApiConfig,
                apiStatus: ApiStatusService,
                http: HttpClient)
    {
        super(apiStatus, http, 'Admin');
        this.apiUrl = `${config.involiApiUrl}/admin`;
    }

    getLoggingLevel(): Observable<LogLevel>
    {
        return this.get<LogLevelDto>(`${this.apiUrl}/logging/level`).pipe(map((dto: LogLevelDto) => dto.level));
    }
    
    setLogginLevel(level: LogLevel): Observable<void>
    {
        const request: LogLevelDto = { level };
        return this.post<void>(`${this.apiUrl}/logging/level`, request);
    }
}
