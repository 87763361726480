import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { AuthModule } from './auth.module';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: AuthModule
})
export class AuthGuard extends KeycloakAuthGuard
{
    constructor(private authService: AuthService,
                private keycloak: KeycloakService,
                protected router: Router)
    {
        super(router, keycloak);
    }

    public async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    {
        const loggedIn: boolean = await this.keycloak.isLoggedIn();
        if(!loggedIn)
            await this.authService.login(state);
        return this.authService.hasValidAccess();
    }
}