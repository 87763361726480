<cdk-tree [dataSource]="menuData" [treeControl]="treeControl">
    <cdk-nested-tree-node *cdkTreeNodeDef="let node" class="node">
        <div class="node-content sub-node" [routerLink]="node.url">
            <span>{{ node.title }}</span>
        </div>
    </cdk-nested-tree-node>

    <cdk-nested-tree-node *cdkTreeNodeDef="let node; when: hasChild" class="node">
        <div class="node-content" [class.expanded]="treeControl.isExpanded(node)" cdkTreeNodeToggle>
            <span>{{ node.title }}</span>
        </div>
        <div [class.invisible-node]="!treeControl.isExpanded(node)">
            <ng-container cdkTreeNodeOutlet></ng-container>
        </div>
    </cdk-nested-tree-node>
</cdk-tree>