import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfig, API_CONFIG } from '../api';
import { Observable } from 'rxjs';
import { MctReferenceFirmwareDto, OperationalStatus, ReceiverEntity, Record, SetOperationalStatusDto, UpdateLatestMctFirmwareDto, AbortMctFirmwareUpdateDto, ClearMctUpdateStatusDto, ReceiverDetail } from 'involi-api-shared';
import { ApiClient } from '../core/api-client';
import { ApiStatusService } from '../core';

@Injectable({
    providedIn: 'root'
})
export class ReceiverApiService extends ApiClient
{
    private apiUrl: string;

    constructor(@Inject(API_CONFIG) config: ApiConfig,
                http: HttpClient,
                apiStatus: ApiStatusService)
    {
        super(apiStatus, http, 'Receiver');
        this.apiUrl = `${config.involiApiUrl}/receiver`;
    }

    getAllReceiverEntities(): Observable<ReceiverEntity[]>
    {
        return this.get<ReceiverEntity[]>(`${this.apiUrl}/all/entity`, []);
    }

    getUserReceiverEntities(userId: string): Observable<ReceiverEntity[]>
    {
        return this.get<ReceiverEntity[]>(`${this.apiUrl}/user/${userId}/entity`, []);
    }

    getCurrentUserReceiverEntities(): Observable<ReceiverEntity[]>
    {
        return this.get<ReceiverEntity[]>(`${this.apiUrl}/entity`, []);
    }

    getUserReceivers(userId: string): Observable<Record[]>
    {
        return this.get<Record[]>(`${this.apiUrl}/user/${userId}`, []);
    }

    getCurrentUserReceivers(): Observable<Record[]>
    {
        return this.get<Record[]>(`${this.apiUrl}`, []);
    }

    getCurrentUserReceiverDetails(): Observable<ReceiverDetail[]>
    {
        return this.get<ReceiverDetail[]>(`${this.apiUrl}/detail`, []);
    }

    getAllReceivers(): Observable<Record[]>
    {
        return this.get<Record[]>(`${this.apiUrl}/all`, []);
    }

    getAllReceiverDetails(): Observable<ReceiverDetail[]>
    {
        return this.get<ReceiverDetail[]>(`${this.apiUrl}/all/detail`, []);
    }

    setReceiverOperationalStatus(receiverId: string, status: OperationalStatus): Observable<void>
    {
        const request = new SetOperationalStatusDto();
        request.operational_status = status;
        return this.post<void>(`${this.apiUrl}/${receiverId}/operational-status`, request);
    }

    getMctFirmwares(): Observable<string[]>
    {
        return this.get<string[]>(`${this.apiUrl}/mct/firmware`);
    }

    syncMcts(): Observable<void>
    {
        return this.get<void>(`${this.apiUrl}/mct/sync`);
    }

    syncMctFirmwares(): Observable<void>
    {
        return this.get<void>(`${this.apiUrl}/mct/firmware/sync`);
    }

    getMctReferenceFirmware(): Observable<MctReferenceFirmwareDto>
    {
        return this.get<MctReferenceFirmwareDto>(`${this.apiUrl}/mct/firmware/reference`);
    }

    setMctReferenceFirmware(firmware: string): Observable<void>
    {
        const request = new MctReferenceFirmwareDto();
        request.version = firmware;
        return this.post<void>(`${this.apiUrl}/mct/firmware/reference`, request);
    }

    updateMctFirmware(artifactName: string, receiverIds: string[])
    {
        const request = new UpdateLatestMctFirmwareDto();
        request.expectedArtifactName = artifactName;
        request.receiverIds = receiverIds;
        return this.post<void>(`${this.apiUrl}/mct/firmware/update-latest`, request);
    }

    abortMctFirmwareUpdate(receiverId: string): Observable<void>
    {
        const request = new AbortMctFirmwareUpdateDto();
        request.receiverId = receiverId;
        return this.post<void>(`${this.apiUrl}/mct/firmware/abort-update`, request);
    }

    clearMctUpdateStatus(receiverId: string): Observable<void>
    {
        const request = new ClearMctUpdateStatusDto();
        request.receiverId = receiverId;
        return this.post<void>(`${this.apiUrl}/mct/firmware/clear-update-status`, request);
    }
}