import { IsOptional, IsString } from 'class-validator';
import { Entity } from '../entity/entity.js';
import { makePrimaryId } from '../entity/utils.js';

export class TrackerEntity extends Entity
{
    @IsString()
    model?: string;

    @IsString()
    serialNumber?: string;

    @IsOptional()
    @IsString()
    stream?: string;

    constructor(baseEntity: Partial<TrackerEntity>)
    {
        super();
        this.serialNumber = baseEntity.serialNumber;
        this.stream = baseEntity.stream;
        if(baseEntity.serialNumber && baseEntity.stream)
            this.id = this.id = makePrimaryId(baseEntity.stream, baseEntity.serialNumber);
    }
}