import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { AuthModule } from './auth.module';

@Injectable({
    providedIn: AuthModule
})
export class AdminGuard extends KeycloakAuthGuard
{
    constructor(keycloak: KeycloakService,
                protected router: Router)
    {
        super(router, keycloak);
    }

    public async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    {
        return this.roles.includes('involi:admin');
    }
}