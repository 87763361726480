import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, Injector, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApiConfig, API_CONFIG, AuthModule, BaseConfiguration, ConfigurationService, ConfigurationModule, AuthService } from 'involi-api-client';
import { AdminPlatformModule } from './admin-platform/admin-platform.module';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { environment } from '../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { GlobalErrorHandler } from './error/global-error-handler.service';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';

export function initializeApp(configurationService: ConfigurationService, injector: Injector)
{
    return async (): Promise<BaseConfiguration> => {
        const configuration = await configurationService.load();
        const authService: AuthService = injector.get(AuthService);
        await authService.init();
        return configuration;
    }
}

export function initializeApiConfiguration(configurationService: ConfigurationService): ApiConfig
{
    return configurationService.configuration.api;
}

export function initializeEntityApiConfiguration(configurationService: ConfigurationService): string
{
    return configurationService.configuration.api.entityApiUrl;
}

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        StoreModule.forRoot({}),
        EffectsModule.forRoot(),
        StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
        AuthModule,
        ConfigurationModule,
        AdminPlatformModule,
        MonacoEditorModule.forRoot()
    ],
    providers: [
        { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [ConfigurationService, Injector], multi: true },
        { provide: API_CONFIG, useFactory: initializeApiConfiguration, deps: [ConfigurationService] },
        { provide: ErrorHandler, useClass: GlobalErrorHandler }
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {}
