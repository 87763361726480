import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfig, API_CONFIG } from '../api';
import { Alarm, AlarmPollDto, AlarmResponseItem } from './alarm';
import { AlarmsApiModule } from './alarms-api.module';

@Injectable({
    providedIn: AlarmsApiModule
})
export class AlarmsApiService
{
    constructor(@Inject(API_CONFIG) private apiConfig: ApiConfig,
                private http: HttpClient)
    {

    }

    pollApi(alarms: string[]): Observable<AlarmResponseItem[]>
    {
        const body: AlarmPollDto = {
            ids: alarms
        };
        return this.http.post<AlarmResponseItem[]>(`${this.apiConfig.alarmsApiUrl}/alarm/poll`, body);
    }

    getAlarms(): Observable<Alarm[]>
    {
        return this.http.get<Alarm[]>(`${this.apiConfig.alarmsApiUrl}/alarms`);
    }
}