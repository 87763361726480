import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfig, API_CONFIG } from '../api';
import { Layer, LayerCondition, CreateLayerDto, LayerStreamAssignDto, WriteConditionDto } from './layer';
import { LayersApiModule } from './layers-api.module';

@Injectable({
    providedIn: LayersApiModule
})
export class LayersApiService
{
    constructor(@Inject(API_CONFIG) private apiConfig: ApiConfig,
                private http: HttpClient)
    {

    }
    
    getAllLayers(): Observable<Layer[]>
    {
        return this.http.get<Layer[]>(`${this.apiConfig.url}/layers/`);
    }

    createLayer(name: string): Observable<Layer>
    {
        const layer: CreateLayerDto = { name: name };
        return this.http.post<Layer>(`${this.apiConfig.url}/layers/`, layer);
    }

    getLayer(uuid: string): Observable<Layer>
    {
        return this.http.get<Layer>(`${this.apiConfig.url}/layers/${uuid}/`);
    }

    updateLayer(uuid: string, layer: Layer): Observable<Layer>
    {
        return this.http.patch<Layer>(`${this.apiConfig.url}/layers/${uuid}/`, layer);
    }
    
    deleteLayer(uuid: string): Observable<void>
    {
        return this.http.delete<void>(`${this.apiConfig.url}/layers/${uuid}/`);
    }

    assignStream(layerUuid: string, streamName: string)
    {
        const layerStreamAssign: LayerStreamAssignDto = { stream: streamName, layer: layerUuid };
        return this.http.post<void>(`${this.apiConfig.url}/layer-streams/`, layerStreamAssign);
    }

    unassignStream(layerUuid: string, streamName: string)
    {
        const layerStreamAssign: LayerStreamAssignDto = { stream: streamName, layer: layerUuid };
        return this.http.request<void>('delete', `${this.apiConfig.url}/layer-streams/`, { body: layerStreamAssign });
    }

    getCondition(id: number): Observable<LayerCondition>
    {
        return this.http.get<LayerCondition>(`${this.apiConfig.url}/layer-conditions/${id}/`);
    }

    createCondition(condition: WriteConditionDto): Observable<LayerCondition>
    {
        return this.http.post<LayerCondition>(`${this.apiConfig.url}/layer-conditions/`, condition);
    }

    updateCondition(id: number, condition: WriteConditionDto): Observable<LayerCondition>
    {
        return this.http.patch<LayerCondition>(`${this.apiConfig.url}/layer-conditions/${id}/`, condition);
    }
    
    deleteCondition(id: number): Observable<void>
    {
        return this.http.delete<void>(`${this.apiConfig.url}/layer-conditions/${id}/`);
    }
}