import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard, AdminGuard } from 'involi-api-client';
import { AdminPlatformComponent } from './admin-platform/admin-platform.component';

const routes: Routes = [
    { path: '', component: AdminPlatformComponent, canActivate: [AuthGuard, AdminGuard], children: [
        { path: 'system', loadChildren: () => import('./system/system.module').then(m => m.SystemModule) },
        { path: 'users', loadChildren: () => import('./users/users.module').then(m => m.UsersModule) },
        { path: 'remote-id', loadChildren: () => import('./remote-id/remote-id.module').then(m => m.RemoteIdModule) },
        { path: 'trackers', loadChildren: () => import('./trackers/trackers.module').then(m => m.TrackersAdminModule) },
        { path: 'features', loadChildren: () => import('./features/features.module').then(m => m.FeaturesModule) },
        { path: 'data', loadChildren: () => import('./data/data.module').then(m => m.DataModule) },
        { path: 'zones', loadChildren: () => import('./zones/zones.module').then(m => m.ZonesModule) },
        { path: 'entity', loadChildren: () => import('./entity/entity.module').then(m => m.EntityModule) },
        { path: 'api', loadChildren: () => import('./api/api.module').then(m => m.ApiModule) },
        { path: 'mct', loadChildren: () => import('./mct/mct.module').then(m => m.MctModule) },
        { path: 'tools', loadChildren: () => import('./tools/tools.module').then(m => m.ToolsModule) }
    ]}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
