import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Record } from 'involi-api-shared';
import { Observable } from 'rxjs';
import { ApiConfig, API_CONFIG } from '../api';
import { GetAllReceiversDto } from './receiver';
import { ReceiversApiModule } from './receivers-api.module';

@Injectable({
    providedIn: ReceiversApiModule
})
export class ReceiversApiService
{
    constructor(@Inject(API_CONFIG) private apiConfig: ApiConfig,
                private http: HttpClient)
    {

    }

    getAllReceivers(stream: string): Observable<Record[]>
    {
        const request: GetAllReceiversDto = { stream };
        return this.http.post<Record[]>(`${this.apiConfig.receiverApiUrl}/receivers/`, request);
    }
}