import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiConfig, API_CONFIG } from '../api';
import { CreateGroupDto, Group, GroupAssignDto } from './group';
import { AddRoleToUserDto, RemoveRoleFromUserDto, UserRole } from './role';
import { CreateUserDto, SetPasswordDto, User } from './user';
import { UsersApiModule } from './users-api.module';

@Injectable({
    providedIn: UsersApiModule
})
export class UsersApiService
{
    constructor(@Inject(API_CONFIG) private apiConfig: ApiConfig,
                private http: HttpClient)
    {
        
    }

    getAllUsers(groupUuid?: string): Observable<User[]>
    {
        let httpParams = new HttpParams();
        if(groupUuid)
            httpParams = httpParams.append('group', groupUuid);
        return this.http.get<User[]>(`${this.apiConfig.url}/users/`, { params: httpParams });
    }

    getUserByUuid(uuid: string): Observable<User>
    {
        return this.http.get<User>(`${this.apiConfig.url}/users/${uuid}/`);
    }

    getCurrentUser(): Observable<User>
    {
        return this.http.get<User>(`${this.apiConfig.url}/profile/`);
    }

    updateCurrentUser(user: User): Observable<User>
    {
        return this.http.patch<User>(`${this.apiConfig.url}/profile/`, user);
    }

    changeCurrentUserPassword(password: string, currentPassword?: string): Observable<void>
    {
        const request: SetPasswordDto = currentPassword === undefined? { password: password } : {password: password, current_password: currentPassword};
        return this.http.put<void>(`${this.apiConfig.url}/password/`, request);
    }

    createUser(email: string): Observable<User>
    {
        const request: CreateUserDto = { email };
        return this.http.post<User>(`${this.apiConfig.url}/users/`, request);
    }

    updateUser(user: User): Observable<User>
    {
        return this.http.patch<User>(`${this.apiConfig.url}/users/${user.uuid}/`, user);
    }

    setUserPassword(userUuid: string, password: string): Observable<void>
    {
        const request: SetPasswordDto = { password };
        return this.http.put<void>(`${this.apiConfig.url}/users/${userUuid}/password/`, request);
    }

    deleteUser(uuid: string): Observable<any>
    {
        return this.http.delete(`${this.apiConfig.url}/users/${uuid}/`);
    }

    getAllGroups(includeIndividual: boolean = false): Observable<Group[]>
    {
        let httpParams: HttpParams = new HttpParams();
        if(includeIndividual)
            httpParams = httpParams.append('include_individual', 'true');
        return this.http.get<Group[]>(`${this.apiConfig.url}/groups/`, { params: httpParams });
    }

    getGroupByUuid(uuid: string): Observable<Group>
    {
        return this.http.get<Group>(`${this.apiConfig.url}/groups/${uuid}/`);
    }

    createGroup(name: string): Observable<Group>
    {
        const request: CreateGroupDto = { name };
        return this.http.post<Group>(`${this.apiConfig.url}/groups/`, request);
    }

    updateGroup(group: Group): Observable<Group>
    {
        return this.http.patch<Group>(`${this.apiConfig.url}/groups/${group.uuid}/`, group);
    }

    deleteGroup(uuid: string): Observable<any>
    {
        return this.http.delete(`${this.apiConfig.url}/groups/${uuid}/`);
    }

    assignUserToGroup(user: User, group: Group): Observable<any>
    {
        const request: GroupAssignDto = {
            user_id: user.uuid,
            group_id: group.uuid
        };
        return this.http.post(`${this.apiConfig.url}/group-assign/`, request);
    }

    removeUserFromGroup(userUuid: string, groupUuid: string)
    { 
        const request: GroupAssignDto = {
            user_id: userUuid,
            group_id: groupUuid
        };
        return this.http.request('delete', `${this.apiConfig.url}/group-assign/`, { body: request });
    }

    getUserRoles(userUuid: string): Observable<UserRole[]>
    {
        const filteredRoles: string[] = ['offline_access', 'uma_authorization'];
        return this.http.get<UserRole[]>(`${this.apiConfig.url}/users/${userUuid}/roles/`).pipe(
            map((userRoles: UserRole[]) => userRoles.filter((userRole: UserRole) => !filteredRoles.includes(userRole.name)))
        );
    }

    addRoleToUser(userRole: UserRole, userUuid: string)
    {
        const request: AddRoleToUserDto = {
            role_id: userRole.id,
            role_name: userRole.name
        };
        return this.http.post(`${this.apiConfig.url}/users/${userUuid}/roles/`, request);
    }

    removeRoleFromUser(userRole: UserRole, userUuid: string)
    { 
        const request: RemoveRoleFromUserDto = {
            role_id: userRole.id,
            role_name: userRole.name
        };
        return this.http.request('delete', `${this.apiConfig.url}/users/${userUuid}/roles/`, { body: request });
    }

    getAvailableRoles(userUuid: string): Observable<UserRole[]>
    {
        return this.http.get<UserRole[]>(`${this.apiConfig.url}/users/${userUuid}/available-roles/`);
    }
}