import { HttpBackend, HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RouterStateSnapshot } from '@angular/router';
import { KeycloakEvent, KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { BehaviorSubject, firstValueFrom, Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ConfigurationService } from '../configuration';
import { User, UsersApiService } from '../users';
import { InvoliAuthConfig } from './auth-config';
import { AuthModule } from './auth.module';
import { Buffer } from 'buffer';

@Injectable({
    providedIn: AuthModule
})
export class AuthService
{
    private readonly REFRESH_TOKEN = 'refresh_token';
    private readonly ACCESS_TOKEN = 'access_token';

    private http: HttpClient;
    private configuration?: InvoliAuthConfig;
    private ready$ = new BehaviorSubject<boolean>(false);
    private currentUser: User;

    constructor(private configurationService: ConfigurationService,
                private usersApi: UsersApiService,
                private keycloak: KeycloakService,
                httpBackend: HttpBackend)
    {
        this.http = new HttpClient(httpBackend);

        this.currentUser = {
            acknowledged_features: [],
            default_group: { uuid: '', name: '', id_individual: true },
            email: '',
            features: [],
            user_groups: [],
            uuid: ''
        };

        this.keycloak.keycloakEvents$.subscribe((event: KeycloakEvent) => {
            if(event.type == KeycloakEventType.OnAuthSuccess)
            {
                localStorage.setItem(this.REFRESH_TOKEN, this.keycloak.getKeycloakInstance().refreshToken!);
                localStorage.setItem(this.ACCESS_TOKEN, this.keycloak.getKeycloakInstance().token!);
            }
        });
    }

    async init(): Promise<boolean>
    {
        if(!this.configurationService.configuration)
            throw new Error('[AuthService] trying to init without configuration');
        this.configuration = this.configurationService.configuration.involiAuthConfig;
        let refreshToken: string | undefined = localStorage.getItem(this.REFRESH_TOKEN) ?? undefined;
        let accessToken: string | undefined = localStorage.getItem(this.ACCESS_TOKEN) ?? undefined;
        if(!refreshToken || !accessToken)
        {
            refreshToken = undefined;
            accessToken = undefined;
        }

        const isAuthenticated: boolean = await this.keycloak.init({
            config: {
                url: this.configuration.baseIssuer,
                realm: this.configuration.currentRealm,
                clientId: this.configuration.clientId
            },
            initOptions: {
                pkceMethod: 'S256',
                checkLoginIframe: false,
                refreshToken: refreshToken,
                token: accessToken
            },
            bearerExcludedUrls: [this.revokeUrl()!]
        }).catch((a) => {
            console.log('init error', a);
            return false;
        });

        if(!isAuthenticated)
            return isAuthenticated;

        const user: User | null = await firstValueFrom(this.usersApi.getCurrentUser().pipe(
            tap(console.log),
            catchError((error: HttpErrorResponse) => { console.log(error); return of(null); })
        ));

        if(user)
            this.currentUser = user;

        this.ready$.next(true);

        return true;
    }

    login(state?: RouterStateSnapshot): Promise<void>
    {
        let redirectUri = window.location.origin;
        if(state) redirectUri += state.url;
        return this.keycloak.login({
            redirectUri,
            scope: 'offline_access'
        });
    }

    isReady(): Observable<boolean>
    {
        return this.ready$.asObservable();
    }

    getJwtToken(): string | undefined
    {
        return this.keycloak.getKeycloakInstance().token
    }

    async getValidJwtToken(): Promise<string | undefined>
    {
        await this.keycloak.updateToken();
        return this.getJwtToken();
    }

    hasValidAccess(): Promise<boolean>
    {
        return this.keycloak.isLoggedIn();
    }

    hasRole(role: string): boolean
    {
        return this.keycloak.getUserRoles().includes(role);
    }

    getSubject(): string | undefined
    {
        return this.keycloak.getKeycloakInstance().subject;
    }

    getCurrentUser(): User
    {
        return this.currentUser;
    }

    setCurrentUser(user: User)
    {
        this.currentUser = user;
    }

    logout()
    {
        if(!this.configuration)
            return;

        const request = new HttpParams()
            .set('token', this.keycloak.getKeycloakInstance().refreshToken!)
            .set('token_type_hint', 'refresh_token');
        localStorage.removeItem(this.REFRESH_TOKEN);
        localStorage.removeItem(this.ACCESS_TOKEN);
        this.http.post(
            this.revokeUrl(),
            request.toString(),
            {
                headers: new HttpHeaders()
                    .set('Content-Type', 'application/x-www-form-urlencoded')
                    .set('Authorization', `Basic ${Buffer.from(this.configuration.clientId + ':').toString('base64')}`)
            }
        ).subscribe(() => {
            this.keycloak.logout(window.location.protocol + '//' + window.location.host);
        });
    }

    private revokeUrl(): string
    {
        return `${this.configuration!.baseIssuer}/realms/${this.configuration!.currentRealm}/protocol/openid-connect/revoke`;
    }
}