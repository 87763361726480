import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SessionsService } from './sessions.service';

@Injectable()
export class SessionsInterceptor implements HttpInterceptor
{
    private readonly SESSION_ID_HEADER = 'X-Involi-Session';

    constructor(private sessionsService: SessionsService)
    {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        this.sessionsService.activity();
        const sessionId: string = this.sessionsService.getSessionId();
        const authReq = req.clone({ headers: req.headers.set(this.SESSION_ID_HEADER, sessionId) });
        return next.handle(authReq);
    }
}